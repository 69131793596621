var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('Breadcrumbs', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('hr'), _c('c-flex', {
    attrs: {
      "mt": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-family": "Montserrat",
      "font-size": "24px",
      "font-weight": "700",
      "line-height": "36px"
    }
  }, [_vm._v(" Detail Notifikasi ")]), _vm.isEditable ? _c('c-stack', {
    attrs: {
      "is-inline": "",
      "spacing": "10px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant-color": "primary",
      "rounded": "full",
      "as": "router-link",
      "to": {
        name: 'superadmin.notifikasi-edit',
        params: _vm.$route.params
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "edit icon",
      "size": "24px"
    }
  }), _c('c-text', {
    attrs: {
      "ml": "5px"
    }
  }, [_vm._v(" Edit ")])], 1), _c('c-button', {
    attrs: {
      "type": "button",
      "rounded": "full",
      "variant-color": "red"
    },
    on: {
      "click": function click($event) {
        return _vm.cancelNotifikasi();
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-trash.svg'),
      "alt": "icon trash"
    }
  }), _c('c-text', {
    attrs: {
      "ml": "5px"
    }
  }, [_vm._v(" Cancel ")])], 1)], 1) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "mt": "20px"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "mt": index === 0 ? '0' : '10px'
      }
    }, [_c('c-text', {
      attrs: {
        "color": "primary.400",
        "font-size": "16px",
        "font-weight": "500",
        "line-height": "24px"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "18px",
        "font-weight": "400",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")])], 1);
  }), _c('c-box', {
    attrs: {
      "mt": "10px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px"
    }
  }, [_vm._v(" Target ")]), _vm.value ? _c('c-checkbox-group', {
    attrs: {
      "is-disabled": ""
    },
    model: {
      value: _vm.value.targetRole,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "targetRole", $$v);
      },
      expression: "value.targetRole"
    }
  }, [_c('c-checkbox', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        pointerEvents: 'none'
      },
      expression: "{ pointerEvents: 'none' }"
    }],
    attrs: {
      "value": "client"
    }
  }, [_vm._v(" Client ")]), _c('c-checkbox', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        pointerEvents: 'none'
      },
      expression: "{ pointerEvents: 'none' }"
    }],
    attrs: {
      "value": "nutritionist"
    }
  }, [_vm._v(" Ahli Gizi ")])], 1) : _vm._e()], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }